<template>
  <div >
    <v-row dense class="my-0">
      <v-col>
          <!-- Grid de Equipamentos -->
          <v-row dense>
          <v-col cols="12" class="text-center blod_color">
            <h3>Selecione o(s) equipamento(s) desejado(s)</h3>
          </v-col>
        </v-row>
        <v-row dense  >
          <v-col cols="12" v-if="loadingEquipamentos" class="text-center">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-col>
          <v-col v-for="(equipamento, index) in formattedEquipamentos" :key="index" cols="4">
            <v-checkbox
              v-model="newEquipamento.equipamentosSelecionados"
              :value="equipamento.id"
              :label="equipamento.displayText"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="1">
            <v-select outlined required v-model="newEquipamento.aditivo_equipamentos" item-value="value"
              item-text="nome" :items="statusOptions" :rules="requiredField" >
              <template v-slot:label>
                <span>Aditivo<span style="color: red;">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="newEquipamento.aditivo_equipamentos" cols="1">
            <v-text-field outlined required v-model="newEquipamento.nr_aditivo" type="number" :rules="nrAditivoRules(newEquipamento)">
              <template v-slot:label>
                <span>Nrº do Aditivo <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-if="newEquipamento.aditivo_equipamentos" cols="2">
            <v-text-field outlined required v-model="newEquipamento.data_aditivo" type="date"  :rules="dataAditivoRules()">
              <template v-slot:label>
                <span>Data Início do Aditivo <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <!-- <v-col cols="3">
            <v-select outlined required v-model="newEquipamento.equipamento" :items="equipamentos" item-value="id" item-text="equipamento.nome"
              :rules="requiredField" :loading="loadingEquipamentos">
              <template v-slot:label>
                <span>Equipamento <span style="color: red;">*</span></span>
              </template>
            </v-select>
          </v-col> -->
          <!-- <v-col cols="2">
            <vuetify-money outlined label=Preço required v-model="newEquipamento.preco" type="number" :rules="requiredField" :readonly="true" :options="options">
            </vuetify-money> 
          </v-col> -->
          <v-col cols="3">
            <v-text-field outlined required v-model="newEquipamento.data_inicio_equipamentos" type="date" :rules="requiredField">
              <template v-slot:label>
                <span>Data de Início <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field outlined required v-model="newEquipamento.data_fim_equipamentos" type="date" :rules="requiredField">
              <template v-slot:label>
                <span>Data de Fim <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field outlined required v-model="newEquipamento.quantidade_equipamentos" type="number" :rules="requiredField"  >
              <template v-slot:label>
                <span>Quantidade <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <!-- <v-col cols="2">
            <v-text-field label="Total" outlined v-model="newEquipamento.valor_cobranca_final_equipamentos" disabled></v-text-field>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-end" dense>
      <ConfirmButton color="success" :loading="loading" :onConfirm="onBeforeSubmit" :block="false">
        Salvar
      </ConfirmButton>
    </v-row>
  </div>

</template>

<script>
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';
import api from "../../../http";

export default {
  name: "GenerateProceduresFormEquipamento",
  props: {
    operadoraId: Number,
    modalidade: Object,
    orcamentoId: Number,
    statusOptions: Array,
    loading: Boolean,
    onSubmit: Function,
    dataInicio: [Date, String],
    dataFim: [Date, String],
  },
  data: () => UtilsFunc.withCRUDUtils({
    validating: false,
    equipamentos: [],
    loadingEquipamentos: false,
    aditivo:[],
    pacoteId:[],
    nomeequipamentos:[],
    maxNrAditivo: null,
    maxDataAditivo: null,
    newEquipamento: {
      equipamentosSelecionados: [],
      equipamento: null,
      preco: '',
      data_inicio_equipamentos: '',
      data_fim_equipamentos: '',
      quantidade_equipamentos: '',
      valor_cobranca_final_equipamentos: '',
      aditivo_equipamentos: false,
      nr_aditivo: null,
      data_aditivo: null,
      operadora: null,
      equipamento_ref: null,
    },
    isLoading: true,
    nrAditivoRules(newEquipamento) {
      if (newEquipamento.aditivo === true) { 
        return [v => !!v || 'Nrº Aditivo é obrigatório'];
      }
      return []; 
    },
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
  }),
  methods: {
    async getEquipamentos() {
      this.loadingEquipamentos = true
      const operadoraId = this.operadoraId;
      try {
        const { data } = await api.get(`operadoras/operadora-equipamentos/?operadora=${operadoraId}`);
        this.equipamentos = data
          .filter(equipamento => equipamento.ativo === true)
          .sort((a, b) => a.equipamento.nome.localeCompare(b.equipamento.nome));
          // console.log(this.equipamentos)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.loadingEquipamentos = false;
      }
    },
    async getNomeEquipamento() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`equipamentos/nomeequipamentos/`);
        this.nomeequipamentos = data.filter(item => item.ativo)
        // console.log('NomeEquipamento', this.nomeequipamentos)
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getAditivo() {
      this.loadingEquipamentos = true
      const orcamentoId = this.orcamentoId;
      try {
        const { data } = await api.get(`atendimentos/aditivo/?sessao=${orcamentoId}`);
        this.aditivo = data;
        this.maxNrAditivo = data.nr_aditivo;
        this.maxDataAditivo = this.formatDate(data.data_aditivo);
        // console.log(this.aditivo); // Debug
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro com a parte do Aditivo. ${error}`);
      } finally {
        this.loadingEquipamentos = false;
      }
    },
    async getPacote() {
      this.loadingTaxas = true
      const operadora = this.operadoraId
      const modalidade = this.modalidade.id
      try {
        const { data } = await api.get(`operadoras/operadora-pacotes/?operadora=${operadora}`);
        const pacote = data.find(item => item.ativo && item.modalidade.id === modalidade);
        this.pacoteId = pacote ? pacote.id : null;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro com a parte do Aditivo. ${error}`);
      } finally {
        this.loadingTaxas = false;
      }
    },
   async onBeforeSubmit() {
  this.validating = true;
  let isValid = true;

  if (this.newEquipamento.aditivo_equipamentos && !this.newEquipamento.nr_aditivo) {
    isValid = false;
    this.$toast.error('Por favor, preencha o Nrº do Aditivo para todos os campos onde Aditivo está como Sim.');
  }
  if (!this.allFieldsValid()) {
    this.$toast.error('Por favor, preencha todos os campos antes de continuar!');
    this.validating = false;
    return;
  }

  if (!isValid) {
    this.validating = false;
    return;
  }

  for (const equipamentoId of this.newEquipamento.equipamentosSelecionados) {
    const selectedEquipamentoObj = this.equipamentos.find(equipamento => equipamento.id === equipamentoId);
    const selectedNomeEquipamento = this.nomeequipamentos.find(nomeequipamento => nomeequipamento.id === (selectedEquipamentoObj ? selectedEquipamentoObj.equipamento.id : null));

    // Calcula o valor de cobrança do equipamento
    const valorCobrancaEquipamento = selectedEquipamentoObj ? parseFloat(selectedEquipamentoObj.preco) : 0;
    
    let valorCobrancaFinal = valorCobrancaEquipamento * this.newEquipamento.quantidade_equipamentos;

    // Verificar se valorCobrancaFinal é uma string antes de chamar replace
    if (typeof valorCobrancaFinal === 'string') {
      valorCobrancaFinal = parseFloat(valorCobrancaFinal.replace('R$', '').replace('.', '').replace(',', '.'));
    } else if (typeof valorCobrancaFinal === 'number') {
      valorCobrancaFinal = parseFloat(valorCobrancaFinal.toFixed(2)); // Certificar-se de que é um número com 2 casas decimais
    } else {
      valorCobrancaFinal = 0; // Definir um valor padrão em caso de tipo inesperado
    }

    const fields = {
      sessao: this.orcamentoId,
      equipamento: equipamentoId,
      data_inicio_equipamento: this.newEquipamento.data_inicio_equipamentos,
      data_fim_equipamento: this.newEquipamento.data_fim_equipamentos,
      quantidade_equipamento: this.newEquipamento.quantidade_equipamentos,
      valor_cobranca_equipamento: valorCobrancaEquipamento,
      valor_cobranca_final_equipamento: valorCobrancaFinal,
      aditivo_equipamento: this.newEquipamento.aditivo_equipamentos,
      pacote: this.pacoteId ? this.pacoteId : null,
      operadora: this.operadoraId,
      equipamento_ref: selectedNomeEquipamento ? selectedNomeEquipamento.id : null,
    };

    if (this.newEquipamento.aditivo_equipamentos) {
      fields.nr_aditivo = this.newEquipamento.nr_aditivo;
      fields.data_aditivo = this.newEquipamento.data_aditivo;
    }

    // Enviar dados para cada equipamento selecionado
    await this.onSubmit(fields);
  }

  this.validating = false;
}
,

    allFieldsValid() {
  return (
    this.newEquipamento.equipamentosSelecionados.length > 0 && // Verifica se ao menos um equipamento foi selecionado
    this.newEquipamento.data_inicio_equipamentos &&
    this.newEquipamento.data_fim_equipamentos &&
    this.newEquipamento.quantidade_equipamentos &&
    this.newEquipamento.quantidade_equipamentos !== '' // Verifica se a quantidade foi preenchida
  );
}
,
    calculateDaysBetweenDates(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDiff = Math.abs(end - start);
      return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    },
    updateQuantityAndTotal() {
      if (this.newEquipamento.data_inicio_equipamentos && this.newEquipamento.data_fim_equipamentos) {
        const days = this.calculateDaysBetweenDates(this.newEquipamento.data_inicio_equipamentos, this.newEquipamento.data_fim_equipamentos);
        this.newEquipamento.quantidade_equipamentos = days;
        this.newEquipamento.valor_cobranca_final_equipamentos = (this.newEquipamento.preco * days).toFixed(2);
      }
    },
    dataAditivoRules() {
      return [
        v => !!v || 'Data Início do Aditivo é obrigatória',
        v => new Date(v) <= new Date(this.dataFim) || 'Data Início do Aditivo não pode ser maior que a Data de Fim do Orçamento.',
      ];
    },
    updateTotal() {
      this.newEquipamento.valor_cobranca_final_equipamentos = (this.newEquipamento.preco * this.newEquipamento.quantidade_equipamentos).toFixed(2);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate() + 1); 
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    },
    validateAndFormatDate(dateString) {
      const date = new Date(dateString);
      const dataInicio = new Date(this.dataInicio);
      const dataFim = new Date(this.dataFim);

      if (date < dataInicio) {
        return this.formatDate(this.dataInicio);
      } else if (date > dataFim) {
        this.$toast.error('Data de Início não pode ser maior que a Data de Fim.');
        return '';
      } else {
        return this.formatDate(date);
      }
    },
  },
  components: { ConfirmButton },
  computed: {
    procedureTotalValue() {
     const basePrice = parseFloat(this.newEquipamento.valor_cobranca_equipamento);
      const discount = this.newEquipamento.desconto_equipamento ? parseFloat(this.newEquipamento.desconto_equipamento) / 100 : 0;
      const addition = this.newEquipamento.acrescimo_equipamento ? parseFloat(this.newEquipamento.acrescimo_equipamento) / 100 : 0;
      const adjustedPrice = (basePrice * (1 + addition - discount)) * this.newEquipamento.quantidade_equipamento;
      return `R$ ${adjustedPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    formattedEquipamentos() {
      return this.equipamentos.map(equipamento => {
        return {
          ...equipamento,
          displayText: equipamento.nomecomercial ? `${equipamento.equipamento.nome} - "${equipamento.nomecomercial}" - Cód.: ${equipamento.codigo}` : (`${equipamento.equipamento.nome} - Cód.: ${equipamento.codigo}`)
        };
      });
    }
  },
  watch: {
    'newEquipamento.data_inicio_equipamentos': 'updateQuantityAndTotal',
    'newEquipamento.data_fim_equipamentos': 'updateQuantityAndTotal',
    'newEquipamento.quantidade_equipamentos': 'updateTotal',
    'newEquipamento.equipamento': function (newVal) {
      if (newVal) {
        const selectedequipamento = this.equipamentos.find(equipamento => equipamento.id === newVal);
        if (selectedequipamento) {
          this.newEquipamento.preco = selectedequipamento.preco;
          this.updateQuantityAndTotal();
        }
      }
    },
    dataFim(newVal) {
      if (newVal) {
        this.newEquipamento.data_fim_equipamentos = newVal;
        this.updateQuantityAndTotal();
      }
    },
    'newEquipamento.aditivo_equipamentos': function (newVal) {
      if (newVal) {
        this.newEquipamento.nr_aditivo = this.maxNrAditivo || 1;
        this.newEquipamento.data_aditivo = this.maxDataAditivo || new Date().toISOString();
        this.newEquipamento.data_inicio_equipamentos = this.maxDataAditivo || new Date().toISOString()
      } else {
        this.newEquipamento.nr_aditivo = null;
        this.newEquipamento.data_aditivo = null;
        this.newEquipamento.data_inicio_equipamentos = this.formatDate(new Date().toISOString().substr(0, 10))
      }
    },
    'newEquipamento.nr_aditivo': function(newVal) {
      if (newVal != null) {
        if (newVal == this.maxNrAditivo) {
          this.newEquipamento.data_aditivo = this.maxDataAditivo || this.formatDate(new Date().toISOString().substr(0, 10));
          this.newEquipamento.data_inicio_equipamentos = this.maxDataAditivo || this.formatDate(new Date().toISOString().substr(0, 10));
        } else {
          const data = this.dataInicio < this.formatDate(new Date().toISOString().substr(0, 10)) ? this.formatDate(new Date().toISOString().substr(0, 10)) : this.dataInicio
          this.newEquipamento.data_aditivo = data;
          this.newEquipamento.data_inicio_equipamentos = this.formatDate(new Date().toISOString().substr(0, 10))
        }
      }
    },
    maxNrAditivo(newVal) {
      if (newVal !== null && newVal !== undefined && newVal !== '') {
        this.newEquipamento.nr_aditivo = newVal;
        this.newEquipamento.data_aditivo = this.maxDataAditivo || this.formatDate(new Date().toISOString());
      }
    },
    maxDataAditivo(newVal) {
      if (newVal !== null && newVal !== undefined && newVal !== '') {
        this.newEquipamento.data_aditivo = newVal;
      }
    },
    
  },
  mounted() {
    this.getPacote();
    this.getNomeEquipamento();
    this.getAditivo().then(() => {
      this.getEquipamentos();
      this.newEquipamento.data_inicio_equipamentos = this.validateAndFormatDate(this.dataInicio);
      this.newEquipamento.data_fim_equipamentos = this.formatDate(this.dataFim);
    });
  },
}
</script>
